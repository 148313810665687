
import { defineComponent, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import ArticleContainer from '@/components/global/ArticleContainer.vue';
import SectionHeader from '@/components/global/SectionHeader.vue';
import ArchiveDescription from '@/components/manuscripts/ArchiveDescription.vue';
import { manuscriptWeb, webWatcher } from '@/api/web';
import { isLoaded } from '@/interfaces/RemoteData';
import { archives, getArchives } from '@/api/manuscripts';
import routeParamToString from '@/router/param_as_string';
import RemoteContent from '@/components/global/RemoteContent.vue';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    ArticleContainer,
    SectionHeader,
    ArchiveDescription,
    RemoteContent,
  },
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();
    const title = computed(() => `${t('navigation.manuscripts')} ${t('global.overview')}`);
    const description = computed(() => `${t('navigation.manuscripts')} ${t('global.overview')}`);
    const canonical = computed(() => `https://corpuscoranicum.org/${route.params.lang}/manuscripts`);
    const alternate_de = computed(() => 'https://corpuscoranicum.org/de/manuscripts');
    const alternate_en = computed(() => 'https://corpuscoranicum.org/en/manuscripts');
    const alternate_fr = computed(() => 'https://corpuscoranicum.org/fr/manuscripts');
    const meta = computed(() => ({
      title: title.value,
      description: description.value,
      link: [
        {
          rel: 'canonical',
          href: canonical.value,
        },
        {
          rel: 'alternate',
          hreflang: 'de',
          href: alternate_de.value,
        },
        {
          rel: 'alternate',
          hreflang: 'en',
          href: alternate_en.value,
        },
        {
          rel: 'alternate',
          hreflang: 'fr',
          href: alternate_fr.value,
        },
        {
          rel: 'alternate',
          hreflang: 'x-default',
          href: alternate_de.value,
        },
      ],
      htmlAttrs: { lang: route.params.lang },
    }));
    useMeta(meta);
    getArchives(routeParamToString(route.params.lang));
    webWatcher(route, 'manuscripts', manuscriptWeb);

    watch(
      () => route.params.lang,
      () => {
        if (route.name === 'ManuscriptsOverview') {
          getArchives(routeParamToString(route.params.lang));
        }
      },
    );

    return {
      manuscriptWeb,
      isLoaded,
      archives,
    };
  },
});
